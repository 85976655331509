<template>
    <div class="tabmenudemo-content">
        <div class="grid p-fluid" >
            <div class="col-12 p-2">
                <Menu :slug="slug" :active="active" />

                <div class="card -m-b-2">
                    <div class="field" v-if="pageLoading">
                        <Skeleton height="4rem" width="100%"/>
                    </div>
                    <div class="field" v-else>
                        <label for="title">Title <span style="color: #ff4444">*</span></label>
                        <InputText
                            :class="{'p-invalid': valid$.title.$invalid && submitted}"
                            id="title" 
                            type="text" 
                            v-model="valid$.title.$model"
                            autofocus                            
                        />
                        <span v-if="valid$.title.$error && submitted">
                            <span
                                id="title-error"
                                v-for="(error, index) of valid$.title.$errors"
                                :key="index"
                            >
                                <small class="p-error">{{error.$message.replace("Value", "Title")}}</small>
                            </span>
                        </span>
                        <small
                            v-else-if="(valid$.title.$invalid && submitted) || valid$.title.$pending.$response"
                            class="p-error"
                        >
                            {{ valid$.title.required.$message.replace("Value", "Title") }}
                        </small>
                    </div>
                    <div class="field" v-if="pageLoading">
                        <Skeleton height="12rem" width="100%"/>
                    </div>
                    <div v-else>
                        <Divider align="left" type="dashed" class="mt-4">
                            <b>Places</b>
                        </Divider>
                        <div class="flex flex-wrap mb-2">
                            <div class="flex flex-grow-1 align-items-center">
                                Write all the places to be visited in this tour. Example: Saint martin, Chhera dwip, Naaf river etc.
                            </div>
                            <div class="flex m-2">
                                <Button
                                    @click="addPlaces"
                                    icon="pi pi-plus"
                                    label="Add Place"
                                    class="p-button p-button-text p-button-raised w-full"
                                />
                            </div>
                        </div>
                        <div class="grid">
                            <div class="col-12 md:col-4" v-for="(item, index) in places" :key="index">
                                <div class="p-inputgroup">
                                    <InputText
                                        :class="{'p-invalid': item.length < 1 && submitted}"
                                        id="places"
                                        v-model="places[index]"
                                    />
                                    <Button
                                        icon="pi pi-times"
                                        v-if="places.length != 1"
                                        class="p-button-danger"
                                        @click="deletePlaces(index)"
                                    />
                                </div>
                                <span v-if="item.length < 1 && submitted">
                                        <small class="p-error">Place is required</small>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="grid mt-4">
                        <div class="field" v-if="pageLoading">
                            <Skeleton height="4rem" width="100%"/>
                        </div>
                        <Divider align="left" type="dashed" v-else>
                            <b>Details</b>
                        </Divider>
                        <div class="col-12 lg:col-3">
                            <div class="field" v-if="pageLoading">
                                <Skeleton height="4rem" width="100%"/>
                            </div>
                            <div class="field" v-else>
                                <label for="start_date">Start Date <span style="color: #ff4444">*</span></label>
                                <Calendar
                                    :class="{'p-invalid': valid$.start_date.$invalid && submitted}"
                                    id="start_date"
                                    v-model="valid$.start_date.$model"
                                    dateFormat="dd-mm-yy"
                                    :showButtonBar="true"
                                    selectionMode="single"
                                    :showIcon="true"
                                    
                                    :minDate="current"
                                    @date-select="changeStartDate"
                                />
                                <span v-if="valid$.start_date.$error && submitted">
                                    <span
                                        id="title-error"
                                        v-for="(error, index) of valid$.start_date.$errors"
                                        :key="index"
                                    >
                                        <small class="p-error">{{error.$message.replace("Value", "Start Date")}}</small>
                                    </span>
                                </span>
                                <small
                                    v-else-if="(valid$.start_date.$invalid && submitted) || valid$.start_date.$pending.$response"
                                    class="p-error"
                                >
                                    {{ valid$.start_date.required.$message.replace("Value", "Start Date") }}
                                </small>
                            </div>
                        </div>
                        <div class="col-12 lg:col-3">
                            <div class="field" v-if="pageLoading">
                                <Skeleton height="4rem" width="100%"/>
                            </div>
                            <div class="field" v-else>
                                <label for="end_date">End Date <span style="color: #ff4444">*</span></label>
                                <Calendar
                                    v-if="valid$.start_date.$model"
                                    :class="{'p-invalid': valid$.end_date.$invalid && submitted}"
                                    id="end_date"
                                    v-model="valid$.end_date.$model"
                                    dateFormat="dd-mm-yy"
                                    :showIcon="true"
                                />
                                <Calendar v-else disable showIcon v-tooltip.top="'Enter Start Date First'"/>
                                <span v-if="valid$.end_date.$error && submitted">
                                    <span
                                        id="title-error"
                                        v-for="(error, index) of valid$.end_date.$errors"
                                        :key="index"
                                    >
                                        <small class="p-error">{{error.$message.replace("Value", "End Date")}}</small>
                                    </span>
                                </span>
                                <small
                                    v-else-if="(valid$.end_date.$invalid && submitted) || valid$.end_date.$pending.$response"
                                    class="p-error"
                                >
                                    {{ valid$.end_date.required.$message.replace("Value", "End Date") }}
                                </small>
                            </div>
                        </div>
                        <div class="col-12 lg:col-3">
                            <div class="field" v-if="pageLoading">
                                <Skeleton height="4rem" width="100%"/>
                            </div>
                            <div class="field" v-else>
                                <label for="booking_deadline">Booking Deadline <span style="color: #ff4444">*</span></label>
                                <Calendar
                                    v-if="valid$.start_date.$model"
                                    :class="{'p-invalid': valid$.booking_deadline.$invalid && submitted}"
                                    id="booking_deadline"
                                    v-model="valid$.booking_deadline.$model"
                                    dateFormat="dd-mm-yy"
                                    :showIcon="true"
                                    :minDate="current"
                                />
                                <Calendar v-else disable showIcon v-tooltip.top="'Enter Start Date First'"/>
                                <span v-if="valid$.booking_deadline.$error && submitted">
                                    <span
                                        id="booking_deadline-error"
                                        v-for="(error, index) of valid$.booking_deadline.$errors"
                                        :key="index"
                                    >
                                        <small class="p-error">{{error.$message.replace("Value", "Booking Deadline")}}</small>
                                    </span>
                                </span>
                                <small
                                    v-else-if="(valid$.booking_deadline.$invalid && submitted) || valid$.booking_deadline.$pending.$response"
                                    class="p-error"
                                >
                                    {{ valid$.booking_deadline.required.$message.replace("Value", "Booking Deadline") }}
                                </small>
                            </div>
                        </div>
                        <div class="col-12 lg:col-3">
                            <div class="field" v-if="pageLoading">
                                <Skeleton height="4rem" width="100%"/>
                            </div>
                            <div class="field" v-else>
                                <label for="cancellation_deadline">Cancellation Deadline <span style="color: #ff4444">*</span></label>
                                <Calendar
                                    v-if="valid$.start_date.$model"
                                    :class="{'p-invalid': valid$.cancellation_deadline.$invalid && submitted}"
                                    id="cancellation_deadline"
                                    v-model="valid$.cancellation_deadline.$model"
                                    dateFormat="dd-mm-yy"
                                    :showIcon="true"
                                    :minDate="current"
                                />
                                <Calendar v-else disable showIcon v-tooltip.top="'Enter Start Date First'"/>
                                <span v-if="valid$.cancellation_deadline.$error && submitted">
                                    <span
                                        id="cancellation_deadline-error"
                                        v-for="(error, index) of valid$.cancellation_deadline.$errors"
                                        :key="index"
                                    >
                                        <small class="p-error">{{error.$message.replace("Value", "Cancellation Deadline")}}</small>
                                    </span>
                                </span>
                                <small
                                    v-else-if="(valid$.cancellation_deadline.$invalid && submitted) || valid$.cancellation_deadline.$pending.$response"
                                    class="p-error"
                                >
                                    {{ valid$.cancellation_deadline.required.$message.replace("Value", "Cancellation Deadline") }}
                                </small>
                            </div>
                        </div>
                        <div class="col-6 lg:col-2">
                            <div class="field" v-if="pageLoading">
                                <Skeleton height="4rem" width="100%"/>
                            </div>
                            <div class="field" v-else>
                                <label for="seat_total">Total Seat <span style="color: #ff4444">*</span></label>
                                <InputNumber 
                                    :class="{'p-invalid': valid$.seat_total.$invalid && submitted}"
                                    id="seat_total"
                                    v-model="valid$.seat_total.$model"
                                    :min="0"
                                />
                                <span v-if="valid$.seat_total.$error && submitted">
                                    <span
                                        id="seat_total-error"
                                        v-for="(error, index) of valid$.seat_total.$errors"
                                        :key="index"
                                    >
                                        <small class="p-error">{{error.$message.replace("Value", "Total Seat")}}</small>
                                    </span>
                                </span>
                                <small
                                    v-else-if="(valid$.seat_total.$invalid && submitted) || valid$.seat_total.$pending.$response"
                                    class="p-error"
                                >
                                    {{ valid$.seat_total.required.$message.replace("Value", "Total Seat") }}
                                </small>
                            </div>
                        </div>
                        <div class="col-6 lg:col-2">
                            <div class="field" v-if="pageLoading">
                                <Skeleton height="4rem" width="100%"/>
                            </div>
                            <div class="field" v-else>
                                <label for="seat_booked">Booked Seat</label>
                                <InputNumber 
                                    :class="{'p-invalid': valid$.seat_booked.$invalid && submitted}"
                                    id="seat_booked"
                                    v-model="valid$.seat_booked.$model"
                                    :min="0"
                                    :max="valid$.seat_total.$model"
                                />
                                <span v-if="valid$.seat_booked.$error && submitted">
                                    <span
                                        id="seat_booked-error"
                                        v-for="(error, index) of valid$.seat_booked.$errors"
                                        :key="index"
                                    >
                                        <small class="p-error">{{error.$message.replace("Value", "Booked Seat")}}</small>
                                    </span>
                                </span>
                                <small
                                    v-else-if="(valid$.seat_booked.$invalid && submitted) || valid$.seat_booked.$pending.$response"
                                    class="p-error"
                                >
                                    {{ valid$.seat_booked.required.$message.replace("Value", "Booked Seat") }}
                                </small>
                            </div>
                        </div>
                        <div class="col-6 lg:col-2">
                            <div class="field" v-if="pageLoading">
                                <Skeleton height="4rem" width="100%"/>
                            </div>
                            <div class="field" v-else>
                                <label for="day">Number of Days <span style="color: #ff4444">*</span></label>
                                <InputNumber 
                                    :class="{'p-invalid': valid$.day.$invalid && submitted}"
                                    id="day"
                                    v-model="valid$.day.$model"
                                    :min="0"
                                />
                                <span v-if="valid$.day.$error && submitted">
                                    <span
                                        id="day-error"
                                        v-for="(error, index) of valid$.day.$errors"
                                        :key="index"
                                    >
                                        <small class="p-error">{{error.$message.replace("Value", "Day")}}</small>
                                    </span>
                                </span>
                                <small
                                    v-else-if="(valid$.day.$invalid && submitted) || valid$.day.$pending.$response"
                                    class="p-error"
                                >
                                    {{ valid$.day.required.$message.replace("Value", "Day") }}
                                </small>
                            </div>
                        </div>
                        <div class="col-6 lg:col-2">
                            <div class="field" v-if="pageLoading">
                                <Skeleton height="4rem" width="100%"/>
                            </div>
                            <div class="field" v-else>
                                <label for="night">Number of Nights <span style="color: #ff4444">*</span></label>
                                <InputNumber
                                    :class="{'p-invalid': valid$.night.$invalid && submitted}"
                                    id="night"
                                    v-model="valid$.night.$model"
                                    :min="0"
                                />
                                <span v-if="valid$.night.$error && submitted">
                                    <span
                                        id="night-error"
                                        v-for="(error, index) of valid$.night.$errors"
                                        :key="index"
                                    >
                                        <small class="p-error">{{error.$message.replace("Value", "Night")}}</small>
                                    </span>
                                </span>
                                <small
                                    v-else-if="(valid$.night.$invalid && submitted) || valid$.night.$pending.$response"
                                    class="p-error"
                                >
                                    {{ valid$.night.required.$message.replace("Value", "Night") }}
                                </small>
                            </div>
                        </div>
                        <div class="col-6 lg:col-2">
                            <div class="field" v-if="pageLoading">
                                <Skeleton height="4rem" width="100%"/>
                            </div>
                            <div class="field" v-else>
                                <label for="meals">Number of Meals <span style="color: #ff4444">*</span></label>
                                <InputNumber 
                                    :class="{'p-invalid': valid$.meals.$invalid && submitted}"
                                    id="meals"
                                    v-model="valid$.meals.$model"
                                    :min="0"
                                />
                                <span v-if="valid$.meals.$error && submitted">
                                    <span
                                        id="meals-error"
                                        v-for="(error, index) of valid$.meals.$errors"
                                        :key="index"
                                    >
                                        <small class="p-error">{{error.$message.replace("Value", "Meals")}}</small>
                                    </span>
                                </span>
                                <small
                                    v-else-if="(valid$.meals.$invalid && submitted) || valid$.meals.$pending.$response"
                                    class="p-error"
                                >
                                    {{ valid$.meals.required.$message.replace("Value", "Meals") }}
                                </small>
                            </div>
                        </div>
                        <div class="col-6 lg:col-2">
                            <div class="field" v-if="pageLoading">
                                <Skeleton height="4rem" width="100%"/>
                            </div>
                            <div class="field" v-else>
                                <label for="tshirt">T-Shirt <span style="color: #ff4444">*</span></label>
                                <Dropdown
                                    :class="{'p-invalid': valid$.tshirt.$invalid && submitted}"
                                    v-model="valid$.tshirt.$model"
                                    :options="[{label: 'Yes', value: 1}, {label: 'No', value: 0}]"
                                    optionLabel="label"
                                    optionValue="value"
                                    placeholder="T-Shirt provided" 
                                />
                                <span v-if="valid$.tshirt.$error && submitted">
                                    <span
                                        id="tshirt-error"
                                        v-for="(error, index) of valid$.tshirt.$errors"
                                        :key="index"
                                    >
                                        <small class="p-error">{{error.$message.replace("Value", "T-Shirt")}}</small>
                                    </span>
                                </span>
                                <small
                                    v-else-if="(valid$.tshirt.$invalid && submitted) || valid$.tshirt.$pending.$response"
                                    class="p-error"
                                >
                                    {{ valid$.tshirt.required.$message.replace("Value", "T-Shirt") }}
                                </small>
                            </div>
                        </div>
                        
                        <div class="col-12 lg:col-4">
                            <div class="field" v-if="pageLoading">
                                <Skeleton height="4rem" width="100%"/>
                            </div>
                            <div class="field" v-else>
                                <label for="type_id">Type <span style="color: #ff4444">*</span></label>
                                <Dropdown
                                    :class="{'p-invalid': valid$.type_id.$invalid && submitted}"
                                    v-model="valid$.type_id.$model"
                                    :options="types"
                                    optionLabel="name"
                                    optionValue="id"
                                    placeholder="Tour type" 
                                />
                                <span v-if="valid$.type_id.$error && submitted">
                                    <span
                                        id="type_id-error"
                                        v-for="(error, index) of valid$.type_id.$errors"
                                        :key="index"
                                    >
                                        <small class="p-error">{{error.$message.replace("Value", "Type")}}</small>
                                    </span>
                                </span>
                                <small
                                    v-else-if="(valid$.type_id.$invalid && submitted) || valid$.type_id.$pending.$response"
                                    class="p-error"
                                >
                                    {{ valid$.type_id.required.$message.replace("Value", "Type") }}
                                </small>
                            </div>
                        </div>
                        <div class="col-12 lg:col-4">
                            <div class="field" v-if="pageLoading">
                                <Skeleton height="4rem" width="100%"/>
                            </div>
                            <div class="field" v-else>
                                <label for="category_id">Catagory <span style="color: #ff4444">*</span></label>
                                <Dropdown 
                                    :class="{'p-invalid': valid$.category_id.$invalid && submitted}"
                                    v-model="valid$.category_id.$model"
                                    :options="categories"
                                    optionLabel="name"
                                    optionValue="id"
                                    placeholder="Tour Catagory"
                                />
                                <span v-if="valid$.category_id.$error && submitted">
                                    <span
                                        id="category_id-error"
                                        v-for="(error, index) of valid$.category_id.$errors"
                                        :key="index"
                                    >
                                        <small class="p-error">{{error.$message.replace("Value", "Category")}}</small>
                                    </span>
                                </span>
                                <small
                                    v-else-if="(valid$.category_id.$invalid && submitted) || valid$.category_id.$pending.$response"
                                    class="p-error"
                                >
                                    {{ valid$.category_id.required.$message.replace("Value", "Category") }}
                                </small>
                            </div>
                        </div>
                        <div class="col-12 lg:col-4">
                            <div class="field" v-if="pageLoading">
                                <Skeleton height="4rem" width="100%"/>
                            </div>
                            <div class="field" v-else>
                                <label for="category_id">Manager <span style="color: #ff4444">*</span></label>
                                 <Dropdown
                                    :class="{'p-invalid': valid$.manager_id.$invalid && submitted}"
                                    v-model="valid$.manager_id.$model"
                                    :options="managers"
                                    optionLabel="name"
                                    optionValue="id"
                                    placeholder="Tour Catagory"
                                />
                                <span v-if="valid$.manager_id.$error && submitted">
                                    <span
                                        id="manager_id-error"
                                        v-for="(error, index) of valid$.manager_id.$errors"
                                        :key="index"
                                    >
                                        <small class="p-error">{{error.$message.replace("Value", "Manager")}}</small>
                                    </span>
                                </span>
                                <small
                                    v-else-if="(valid$.manager_id.$invalid && submitted) || valid$.manager_id.$pending.$response"
                                    class="p-error"
                                >
                                    {{ valid$.manager_id.required.$message.replace("Value", "Manager") }}
                                </small>
                            </div>
                        </div>
                        <Divider align="left" type="dashed" v-if="!pageLoading">
                            <b>Prices</b>
                        </Divider>
                        <div class="col-12 lg:col-3">
                            <div class="field" v-if="pageLoading">
                                <Skeleton height="4rem" width="100%"/>
                            </div>
                            <div class="field" v-else>
                                <label for="price_adults">Price Adults <span style="color: #ff4444">*</span></label>
                                <InputNumber
                                    :class="{'p-invalid': valid$.price_adults.$invalid && submitted}"
                                    id="price_adults"
                                    v-model="valid$.price_adults.$model"
                                    prefix="BDT "
                                    :max="100000000"
                                />
                                <span v-if="valid$.price_adults.$error && submitted">
                                    <span
                                        id="price_adults-error"
                                        v-for="(error, index) of valid$.price_adults.$errors"
                                        :key="index"
                                    >
                                        <small class="p-error">{{error.$message.replace("Value", "Adult Price")}}</small>
                                    </span>
                                </span>
                                <small
                                    v-else-if="(valid$.price_adults.$invalid && submitted) || valid$.price_adults.$pending.$response"
                                    class="p-error"
                                >
                                    {{ valid$.price_adults.required.$message.replace("Value", "Adult Price") }}
                                </small>
                            </div>
                        </div>
                        <div class="col-12 lg:col-3">
                            <div class="field" v-if="pageLoading">
                                <Skeleton height="4rem" width="100%"/>
                            </div>
                            <div class="field" v-else>
                                <label for="discounted_price_adults">Discount Price Adults <span style="color: #ff4444">*</span></label>
                                <InputNumber 
                                    :class="{'p-invalid': valid$.discounted_price_adults.$invalid && submitted}"
                                    id="discounted_price_adults"
                                    v-model="valid$.discounted_price_adults.$model"
                                    prefix="BDT "
                                    :max="valid$.price_adults.$model"
                                />
                                <span v-if="valid$.discounted_price_adults.$error && submitted">
                                    <span
                                        id="discounted_price_adults-error"
                                        v-for="(error, index) of valid$.discounted_price_adults.$errors"
                                        :key="index"
                                    >
                                        <small class="p-error">{{error.$message.replace("Value", "Discount Adult Price")}}</small>
                                    </span>
                                </span>
                                <small
                                    v-else-if="(valid$.discounted_price_adults.$invalid && submitted) || valid$.discounted_price_adults.$pending.$response"
                                    class="p-error"
                                >
                                    {{ valid$.discounted_price_adults.required.$message.replace("Value", "Discount Adult Price") }}
                                </small>
                            </div>
                        </div>
                        <div class="col-12 lg:col-3">
                            <div class="field" v-if="pageLoading">
                                <Skeleton height="4rem" width="100%"/>
                            </div>
                            <div class="field" v-else>
                                <label for="price_children">Price Children <span style="color: #ff4444">*</span></label>
                                <InputNumber
                                    :class="{'p-invalid': valid$.price_children.$invalid && submitted}"
                                    id="price_children"
                                    v-model="valid$.price_children.$model"
                                    prefix="BDT "
                                    :max="100000000"
                                />
                                <span v-if="valid$.price_children.$error && submitted">
                                    <span
                                        id="price_children-error"
                                        v-for="(error, index) of valid$.price_children.$errors"
                                        :key="index"
                                    >
                                        <small class="p-error">{{error.$message.replace("Value", "Children Price")}}</small>
                                    </span>
                                </span>
                                <small
                                    v-else-if="(valid$.price_children.$invalid && submitted) || valid$.price_children.$pending.$response"
                                    class="p-error"
                                >
                                    {{ valid$.price_children.required.$message.replace("Value", "Children Price") }}
                                </small>
                            </div>
                        </div>
                        <div class="col-12 lg:col-3">
                            <div class="field" v-if="pageLoading">
                                <Skeleton height="4rem" width="100%"/>
                            </div>
                            <div class="field" v-else>
                                <label for="discounted_price_children">Discount Price Children <span style="color: #ff4444">*</span></label>
                                <InputNumber 
                                    :class="{'p-invalid': valid$.discounted_price_children.$invalid && submitted}"
                                    id="discounted_price_children"
                                    v-model="valid$.discounted_price_children.$model"
                                    prefix="BDT "
                                    :max="valid$.price_children.$model"
                                />
                                <span v-if="valid$.discounted_price_children.$error && submitted">
                                    <span
                                        id="discounted_price_children-error"
                                        v-for="(error, index) of valid$.discounted_price_children.$errors"
                                        :key="index"
                                    >
                                        <small class="p-error">{{error.$message.replace("Value", "Discount Children Price")}}</small>
                                    </span>
                                </span>
                                <small
                                    v-else-if="(valid$.discounted_price_children.$invalid && submitted) || valid$.discounted_price_children.$pending.$response"
                                    class="p-error"
                                >
                                    {{ valid$.discounted_price_children.required.$message.replace("Value", "Discount Children Price") }}
                                </small>
                            </div>
                        </div>
                    </div>
                    <Divider align="left" type="dashed" v-if="!pageLoading">
                        <b>Description</b>
                    </Divider>
                    <div class="field" v-if="pageLoading">
                        <Skeleton height="14rem" width="100%"/>
                    </div>
                    <div class="field" v-else>
                        <Editor
                            id="description"
                            v-model="valid$.description.$model"
                            :autoResize="true"
                            editorStyle="height: 120px"
                            tabindex="-1"
                        >
                            <template v-slot:toolbar>
                                <span class="ql-formats">
                                    <select class="ql-font">
                                        <option selected="selected"></option>
                                        <option value="serif"></option>
                                        <option value="monospace"></option>
                                    </select>
                                    <select class="ql-size">
                                        <option value="small"></option>
                                        <!-- Note a missing, thus falsy value, is used to reset to default -->
                                        <option selected></option>
                                        <option value="large"></option>
                                        <option value="huge"></option>
                                    </select>
                                </span>
                                <span class="ql-formats">
                                    <button class="ql-bold"></button>
                                    <button class="ql-italic"></button>
                                    <button class="ql-underline"></button>
                                    <button class="ql-strike"></button>
                                </span>
                                <span class="ql-formats">
                                    <select class="ql-color">
                                            <option selected="selected"></option><option value="#e60000"></option><option value="#ff9900"></option><option value="#ffff00"></option><option value="#008a00"></option><option value="#0066cc"></option><option value="#9933ff"></option><option value="#ffffff"></option><option value="#facccc"></option><option value="#ffebcc"></option><option value="#ffffcc"></option><option value="#cce8cc"></option><option value="#cce0f5"></option><option value="#ebd6ff"></option><option value="#bbbbbb"></option><option value="#f06666"></option><option value="#ffc266"></option><option value="#ffff66"></option><option value="#66b966"></option><option value="#66a3e0"></option><option value="#c285ff"></option><option value="#888888"></option><option value="#a10000"></option><option value="#b26b00"></option><option value="#b2b200"></option><option value="#006100"></option><option value="#0047b2"></option><option value="#6b24b2"></option><option value="#444444"></option><option value="#5c0000"></option><option value="#663d00"></option><option value="#666600"></option><option value="#003700"></option><option value="#002966"></option><option value="#3d1466"></option>
                                    </select>
                                    <select class="ql-background">
                                        <option value="#000000"></option><option value="#e60000"></option><option value="#ff9900"></option><option value="#ffff00"></option><option value="#008a00"></option><option value="#0066cc"></option><option value="#9933ff"></option><option selected="selected"></option><option value="#facccc"></option><option value="#ffebcc"></option><option value="#ffffcc"></option><option value="#cce8cc"></option><option value="#cce0f5"></option><option value="#ebd6ff"></option><option value="#bbbbbb"></option><option value="#f06666"></option><option value="#ffc266"></option><option value="#ffff66"></option><option value="#66b966"></option><option value="#66a3e0"></option><option value="#c285ff"></option><option value="#888888"></option><option value="#a10000"></option><option value="#b26b00"></option><option value="#b2b200"></option><option value="#006100"></option><option value="#0047b2"></option><option value="#6b24b2"></option><option value="#444444"></option><option value="#5c0000"></option><option value="#663d00"></option><option value="#666600"></option><option value="#003700"></option><option value="#002966"></option><option value="#3d1466"></option>
                                    </select>
                                </span>
                                <span class="ql-formats">
                                    <button class="ql-script" value="sub"></button>
                                    <button class="ql-script" value="super"></button>
                                </span>
                                <span class="ql-formats">
                                    <button class="ql-header" value="1"></button>
                                    <button class="ql-header" value="2"></button>
                                    <button class="ql-blockquote"></button>
                                    <button class="ql-code-block"></button>
                                </span>
                                <span class="ql-formats">
                                    <button class="ql-list" value="ordered"></button>
                                    <button class="ql-list" value="bullet"></button>
                                    <button class="ql-indent" value="-1"></button>
                                    <button class="ql-indent" value="+1"></button>
                                </span>
                                <span class="ql-formats">
                                    <button class="ql-direction ql-active" value="rtl" type="button"></button>
                                    <select class="ql-align">
                                        <option selected="selected"></option><option value="center"></option><option value="right"></option><option value="justify"></option>
                                    </select>
                                </span>
                            </template>
                        </Editor>
                    </div>
                    <br>
                    <br>
                    <Button v-if="!pageLoading" label="Update Basic Information" icon="pi pi-check" :loading="loading" @click="updateBasic(!valid$.$invalid)" class="p-button-outlined mt-2" style="width: 80%; margin: auto; display: block;" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, reactive } from '@vue/reactivity';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import Axios from 'axios';
import store from '../../../vuex';
import moment from 'moment';
import useVuelidate from '@vuelidate/core';
import { required, maxLength, minLength, minValue, numeric } from '@vuelidate/validators';
import Menu from './TabMenu.vue';

export default {
    components: {
        Menu
    },
    setup(){
        const router = useRouter();
        const route = useRoute();
        const toast = useToast();
        const loading = ref(false);
        const pageLoading = ref(true);
        const tour = ref();
        const submitted = ref(false);
        const current = new Date();

        const slug = ref();
        slug.value = route.params.slug;
        const active = ref(1);

        const state = reactive({
            title: "",
            seat_total: "",
            seat_booked: "",
            start_date: "",
            end_date: "",
            day: "",
            night: "",
            meals: "",
            tshirt: "",
            type_id: "",
            category_id: "",
            manager_id: "",
            description: "",
            booking_deadline: "",
            cancellation_deadline: "",
            price_adults: "",
            discounted_price_adults: "",
            price_children: "",
            discounted_price_children: "",
        });

        const rules = {
            title: {required, maxLength: maxLength(255), minLength: minLength(3)},
            seat_total: {required, minValue: minValue(1)},
            seat_booked: {required, minValue: minValue(0)},
            start_date: {required},
            end_date: {required},
            day: { required, numeric, minValue: minValue(0) },
            night: { required, numeric, minValue: minValue(0) },
            meals: { required, numeric, minValue: minValue(0) },
            tshirt : { required },
            type_id: { required },
            category_id: { required },
            manager_id: { required },
            booking_deadline: { required },
            cancellation_deadline: { required },
            price_adults: { required, minValue: minValue(0) },
            discounted_price_adults: { minValue: minValue(0) },
            price_children: { required, minValue: minValue(0) },
            discounted_price_children: { minValue: minValue(0) },
            description: {},
            tour_policy: {},
            children_policy: {},
            cancellation_policy: {},
        };

        const valid$ = useVuelidate (rules, state);
        
        const places = ref(['']);

        const types = ref();
        const categories = ref();
        const managers = ref([]);

        const getSingleTour = () =>{
            pageLoading.value = true;
            Axios
            .get("api/tour/view/"+slug.value)
            .then((res) => {
                if(res.data.response=="success") {
                    pageLoading.value = false;
                    tour.value=res.data.data;

                    state.title = res.data.data.title;
                    state.seat_total = parseInt(res.data.data.seat_total);
                    state.seat_booked = parseInt(res.data.data.seat_booked);
                    state.start_date = res.data.data.start_date;
                    state.end_date = res.data.data.end_date;
                    state.day = parseInt(res.data.data.day);
                    state.night = parseInt(res.data.data.night);
                    state.meals = parseInt(res.data.data.meals);
                    state.tshirt = res.data.data.tshirt;
                    places.value = res.data.data.places;
                    state.type_id = res.data.data.type_id;
                    state.category_id = res.data.data.category_id;
                    state.manager_id = res.data.data.manager_id;
                    state.description = res.data.data.description;
                    state.booking_deadline = res.data.data.booking_deadline;
                    state.cancellation_deadline = res.data.data.cancellation_deadline;
                    state.price_adults = parseFloat(res.data.data.price_adults);
                    state.discounted_price_adults = parseFloat(res.data.data.discounted_price_adults);
                    state.price_children = parseFloat(res.data.data.price_children);
                    state.discounted_price_children = parseFloat(res.data.data.discounted_price_children);
                }
                else {
                    console.log(res.data.message);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        }
        getSingleTour();

        const getTypesCategorys = () =>{
            Axios
            .get("api/data/tour-types-and-categories")
            .then((res) =>{
                if (res.data.response == "success") {
                    categories.value = res.data.data.categories;
                    types.value = res.data.data.types;
                }
                else{
                    console.log(res.data.message);
                }
            })
            .catch((err) =>{
                console.log(err);
            })
        };
        getTypesCategorys();

        const getManagerData = () =>{
            var userData = store.getters.userData;
            var user = JSON.parse(userData);
            if(user.role == 'owner'){
                Axios
                .get("api/operator/agency-manager/list")
                .then((res) =>{
                    if (res.data.response == "success") {
                        managers.value = res.data.data.data;
                    }
                    else{
                        console.log(res.data.message);
                    }
                })
                .catch((err) =>{
                    console.log(err);
                })
            }else{
                managers.value[0] = user;
            }
        }
        getManagerData();

        const addPlaces =  () => {
            places.value.push('');
        };

        const deletePlaces =  (index) => {
            places.value.splice(index, 1);
        };

        const showToast = (severity, summary, detail) => {
            toast.add({severity: severity, summary: summary, detail: detail, life: 5000});
        };

        const backToDetails = () => {
            router.replace({ name: 'details', params: {slug: slug.value}});
        };
        
        const changeStartDate = () => {
            state.end_date = "";
            state.booking_deadline = "";
        };

        const updateBasic = (isFormValid) =>{
            submitted.value = true;
            if (!isFormValid) {
                return;
            }
            const formData1 = new FormData();

            loading.value = true;

            const agency_id = ref();
            const userData = ref();

            userData.value = store.getters.userData;
            userData.value = JSON.parse(userData.value);

            agency_id.value = userData.value.agency_id;

            formData1.append("title", state.title);
            formData1.append("seat_total", state.seat_total);
            formData1.append("seat_booked", state.seat_booked);
            formData1.append("start_date", moment(state.start_date).format("YYYY-MM-DD"));
            formData1.append("end_date", moment(state.end_date).format("YYYY-MM-DD"));
            formData1.append("day", state.day);
            formData1.append("night", state.night);
            formData1.append("meals", state.meals);
            formData1.append("tshirt", state.tshirt);
            for(let i=0; i<places.value.length; i++){
            formData1.append("places[]", places.value[i]);
            }
            formData1.append("type_id", state.type_id);
            formData1.append("category_id", state.category_id);
            formData1.append("description", state.description);
            formData1.append("booking_deadline", moment(state.booking_deadline).format("YYYY-MM-DD"));
            formData1.append("cancellation_deadline", moment(state.cancellation_deadline).format("YYYY-MM-DD"));
            formData1.append("price_adults", state.price_adults);
            formData1.append("discounted_price_adults", state.discounted_price_adults);
            formData1.append("price_children", state.price_children);
            formData1.append("discounted_price_children", state.discounted_price_children);
            formData1.append("agency_id", agency_id.value);
            formData1.append("manager_id", state.manager_id);
            formData1.append("_method", "PUT");

            Axios
            .post(`api/operator/tour/update-basics/${tour.value.id}`, formData1, {
                config: {
                    headers: {'Content-Type': 'multipart/form-data' }
                }
            })
            .then((res) => {
                loading.value = false;
                if(res.data.response == "success") {
                    showToast("success", "Success", res.data.message);
                    slug.value = res.data.data.slug;
                    getSingleTour();
                }
                else{
                    loading.value = false;
                    showToast("error", "Error", res.data.message);
                    console.log(res.data.message);
                }
            })
            .catch((err) => {
                loading.value = false;
                console.log(err);
                showToast("error", "Error", "Something went wrong. Try again!");
            });

        };

        return{
            valid$,
            submitted,
            current,
            
            tour,
            loading,
            pageLoading,
            types,
            categories,
            managers,
            slug,
            active,

            updateBasic,
            backToDetails,
            changeStartDate,
            state,
            addPlaces,
            deletePlaces,
            places
        }
    }
}
</script>

<style scoped>
    .card{
        margin-bottom: 0px !important;
    }
</style>